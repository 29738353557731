import React, { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Calendar } from 'lucide-react';

const DueDateCalculator = () => {
  const [dueDate, setDueDate] = useState('');
  const [pregnancyInfo, setPregnancyInfo] = useState(null);

  const calculatePregnancy = () => {
    if (!dueDate) return;

    const today = new Date();
    const dueDateObj = new Date(dueDate);
    const pregnancyStart = new Date(dueDateObj.getTime() - 280 * 24 * 60 * 60 * 1000);
    const totalDays = Math.floor((today - pregnancyStart) / (24 * 60 * 60 * 1000));
    const currentWeek = Math.floor(totalDays / 7) + 1;
    const weeksLeft = 40 - currentWeek;
    const daysLeft = 280 - totalDays;

    setPregnancyInfo({
      currentWeek,
      weeksLeft,
      daysLeft,
      trimester: currentWeek <= 13 ? '1st' : currentWeek <= 26 ? '2nd' : '3rd',
      percentComplete: Math.min(Math.round((totalDays / 280) * 100), 100),
      pregnancyStart,
      dueDate: dueDateObj,
    });
  };

  const pregnancyTimeline = useMemo(() => {
    if (!pregnancyInfo) return [];

    return Array.from({ length: 42 }, (_, index) => {
      const weekNumber = index + 1;
      const weekStart = new Date(pregnancyInfo.pregnancyStart.getTime() + index * 7 * 24 * 60 * 60 * 1000);
      const weekEnd = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000);

      let milestone = '';
      switch (weekNumber) {
        case 4: milestone = "Implantation occurs"; break;
        case 8: milestone = "Baby is the size of a raspberry"; break;
        case 12: milestone = "Baby's fingers and toes are formed"; break;
        case 16: milestone = "Baby can make sucking motions"; break;
        case 20: milestone = "You might feel the baby's first movements"; break;
        case 24: milestone = "Baby's face is fully formed"; break;
        case 28: milestone = "Baby can blink its eyes"; break;
        case 32: milestone = "Baby's bones are fully developed"; break;
        case 36: milestone = "Baby is practicing breathing movements"; break;
        case 40: milestone = "Baby is considered full-term"; break;
        default: break;
      }

      return {
        week: weekNumber,
        dateRange: `${weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`,
        trimester: weekNumber <= 13 ? '1st' : weekNumber <= 26 ? '2nd' : '3rd',
        milestone,
        isCurrent: weekNumber === pregnancyInfo.currentWeek,
      };
    });
  }, [pregnancyInfo]);

  return (
    <div className="max-w-full mx-auto p-4">
      <Helmet>
        <title>Due Date Calculator | Mumma Care</title>
        <meta name="description" content="Calculate your pregnancy timeline and track important milestones with our easy-to-use due date calculator." />
      </Helmet>
      
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Due Date Calculator</h2>
      
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-6">
        <p className="mb-4 text-gray-600">
          Enter your expected due date to see a summary of your pregnancy progress and a week-by-week breakdown.
        </p>
        
        <div className="mb-4">
          <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700 mb-1">
            Expected Due Date
          </label>
          <div className="relative">
            <input
              type="date"
              id="dueDate"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              className="mt-1 block w-full pl-10 pr-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm rounded-md cursor-pointer"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Calendar className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </div>

        <button
          onClick={calculatePregnancy}
          className="w-full bg-pink-500 text-white py-2 px-4 rounded-md hover:bg-pink-600 transition duration-300"
        >
          Calculate
        </button>
      </div>

      {pregnancyInfo && (
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
          <div className="mb-6">
            <div className="bg-pink-100 p-4 md:p-6 rounded-lg mb-4">
              <div className="text-3xl md:text-4xl text-pink-600 font-bold text-center mb-2">
                Week {pregnancyInfo.currentWeek}
              </div>
              <div className="text-center text-gray-600">
                {pregnancyInfo.currentWeek - 1} weeks {7 - (pregnancyInfo.daysLeft % 7)} days
              </div>
            </div>

            <div className="mb-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-pink-600 h-2.5 rounded-full" 
                  style={{ width: `${pregnancyInfo.percentComplete}%` }}
                ></div>
              </div>
            </div>

            <p className="text-base md:text-lg mb-2">You are {pregnancyInfo.percentComplete}% of the way through your pregnancy.</p>
            <p className="text-base md:text-lg mb-2">You are in the {pregnancyInfo.trimester} trimester.</p>
            <p className="text-base md:text-lg mb-4">Weeks left until due date: {pregnancyInfo.weeksLeft}</p>
          </div>

          <h3 className="text-xl font-semibold mb-4 text-pink-600">Pregnancy Timeline</h3>
          
          {/* Desktop view */}
          <div className="hidden md:block overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300 text-sm">
              <thead className="bg-pink-100">
                <tr>
                  <th className="border border-gray-300 px-2 py-1 text-left font-medium text-pink-700">Week</th>
                  <th className="border border-gray-300 px-2 py-1 text-left font-medium text-pink-700">Date Range</th>
                  <th className="border border-gray-300 px-2 py-1 text-left font-medium text-pink-700">Trimester</th>
                  <th className="border border-gray-300 px-2 py-1 text-left font-medium text-pink-700">Milestone</th>
                </tr>
              </thead>
              <tbody>
                {pregnancyTimeline.map((week) => (
                  <tr key={week.week} className={week.isCurrent ? 'bg-pink-100' : ''}>
                    <td className="border border-gray-300 px-2 py-1 whitespace-nowrap text-sm font-medium">
                      {week.week} {week.isCurrent && <span className="text-pink-600 font-bold">(Current)</span>}
                    </td>
                    <td className="border border-gray-300 px-2 py-1 whitespace-nowrap text-xs">
                      {week.dateRange}
                    </td>
                    <td className="border border-gray-300 px-2 py-1 whitespace-nowrap text-xs">
                      <span className={`px-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                        ${week.trimester === '1st' ? 'bg-blue-100 text-blue-800' : 
                          week.trimester === '2nd' ? 'bg-green-100 text-green-800' : 
                          'bg-purple-100 text-purple-800'}`}>
                        {week.trimester}
                      </span>
                    </td>
                    <td className="border border-gray-300 px-2 py-1 text-xs">
                      {week.milestone && <span className="text-pink-600 font-semibold">{week.milestone}</span>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile view */}
          <div className="md:hidden">
            {pregnancyTimeline.map((week) => (
              <div key={week.week} className={`mb-4 p-3 rounded ${week.isCurrent ? 'bg-pink-100' : 'bg-gray-50'}`}>
                <div className="flex justify-between items-center mb-1">
                  <span className="font-medium">Week {week.week}</span>
                  <span className={`px-2 text-xs font-semibold rounded-full 
                    ${week.trimester === '1st' ? 'bg-blue-100 text-blue-800' : 
                      week.trimester === '2nd' ? 'bg-green-100 text-green-800' : 
                      'bg-purple-100 text-purple-800'}`}>
                    {week.trimester} Trimester
                  </span>
                </div>
                <div className="text-xs text-gray-600 mb-1">{week.dateRange}</div>
                {week.milestone && (
                  <div className="text-sm text-pink-600 font-semibold mt-1">{week.milestone}</div>
                )}
                {week.isCurrent && (
                  <div className="text-sm font-bold text-pink-600 mt-1">Current Week</div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DueDateCalculator;