import React, { useState } from 'react';
import { CheckCircle, XCircle, Eye } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useGameState } from '../hooks/UseGameState';

const emojiQuestions = [
  { emojis: "👶🚿", answer: "Baby Shower" },
  { emojis: "🍼🕰️", answer: "Feeding Time" },
  { emojis: "🦷", answer: "Teething" },
  { emojis: "🤰📅", answer: "Due Date" },
  { emojis: "1️⃣👣", answer: "First Steps" },
  { emojis: "🎵👶", answer: "Lullaby" },
  { emojis: "👶🛁", answer: "Bath Time" },
  { emojis: "🍼🔨", answer: "Bottle Prep" },
  { emojis: "👶💤", answer: "Nap Time" },
  { emojis: "🤱👩‍🍼", answer: "Breastfeeding" },
  { emojis: "🚗🪑", answer: "Car Seat" },
  { emojis: "👶📸", answer: "Baby Photos" },
  { emojis: "🧸👶", answer: "Playtime" },
  { emojis: "👶🌙", answer: "Bedtime" },
  { emojis: "👶🩺", answer: "Check-up" },
  { emojis: "👶🎂", answer: "First Birthday" },
  { emojis: "👨‍👩‍👶", answer: "Family" },
  { emojis: "👶💩🪙", answer: "Diaper Change" },
  { emojis: "🤰🍿", answer: "Pregnancy Cravings" },
  { emojis: "1️⃣🗣️", answer: "First Words" }
];

const BabyShowerGuessTheEmojiGame = () => {
  const { gameState, saveGameState, formatTimeRemaining } = useGameState('babyShowerEmojiGuess');
  const [showModal, setShowModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);

  const handleAnswer = (index, answer) => {
    const isCorrect = answer.toLowerCase() === emojiQuestions[index].answer.toLowerCase();
    const newGameState = {
      ...gameState,
      [index]: { answer, isCorrect, revealed: isCorrect }
    };
    saveGameState(newGameState);
  };

  const handleViewAnswer = (index) => {
    setCurrentQuestionIndex(index);
    setShowModal(true);
  };

  const revealAnswer = () => {
    const newGameState = {
      ...gameState,
      [currentQuestionIndex]: { answer: emojiQuestions[currentQuestionIndex].answer, isCorrect: false, revealed: true }
    };
    saveGameState(newGameState);
    setShowModal(false);
  };

  const computeScore = () => {
    return Object.values(gameState).filter(answer => answer.isCorrect).length;
  };

  return (
    <>
      <Helmet>
        <title>Baby Shower Guess the Emoji Game | Mumma Care</title>
        <meta name="description" content="Play our fun Baby Shower Guess the Emoji Game! Decode cute baby-related emoji combinations and test your baby knowledge." />
        <meta name="keywords" content="baby shower game, emoji game, baby knowledge, pregnancy fun" />
        <meta property="og:title" content="Baby Shower Guess the Emoji Game | Mumma Care" />
        <meta property="og:description" content="Decode cute baby-related emoji combinations and test your baby knowledge in this fun game!" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mummacare.com/baby-shower-guess-the-emoji-game" />
      </Helmet>

      <div className="max-w-6xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Baby Shower Guess the Emoji Game</h1>
        <p className="text-xl text-gray-600 mb-2">Can you guess what these baby-related emoji combinations mean?</p>
        <p className="mb-4" style={{ fontSize: 'small' }}>The game will reset in: {formatTimeRemaining()}</p>
        <p className="text-xl font-bold text-pink-600 mb-8">Your Score: {computeScore()} / {emojiQuestions.length}</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {emojiQuestions.map((question, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-4xl font-semibold mb-4 text-center">{question.emojis}</h3>
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Your guess"
                  className="flex-grow border rounded-md px-2 py-1 mr-2"
                  value={gameState[index]?.answer || ''}
                  onChange={(e) => handleAnswer(index, e.target.value)}
                  disabled={gameState[index]?.isCorrect || gameState[index]?.revealed}
                />
                {gameState[index]?.isCorrect ? (
                  <CheckCircle className="text-green-500" size={24} />
                ) : gameState[index]?.revealed ? (
                  <XCircle className="text-red-500" size={24} />
                ) : (
                  <Eye 
                    className="text-gray-400 cursor-pointer hover:text-gray-600" 
                    size={24} 
                    onClick={() => handleViewAnswer(index)}
                  />
                )}
              </div>
              {gameState[index]?.revealed && !gameState[index]?.isCorrect && (
                <p className="mt-2 text-sm text-gray-600">Correct answer: {question.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-4">View Answer?</h2>
            <p className="mb-4">Are you sure you want to view the answer? You will lose the point for this question.</p>
            <div className="flex justify-end space-x-4">
              <button 
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button 
                onClick={revealAnswer}
                className="px-4 py-2 bg-pink-500 text-white rounded-md hover:bg-pink-600"
              >
                View Answer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BabyShowerGuessTheEmojiGame;