import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Activity, Gift, Star, Smile } from 'lucide-react';

const HomePage = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Welcome to Mumma Care</h1>
        <p className="text-xl text-gray-600 mb-8">
        Welcome, mama! 🤰✨ Mumma Care is your pregnancy bestie, packed with easy-to-use tools and fun games. Whether you're counting kicks or planning your shower, we're here to make your journey smoother and more joyful. Let's rock this pregnancy together!
        </p>
      
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Pregnancy Essentials</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <Link to="/pregnancy-calculator-due-date-schedule" className="block h-full">
          <ToolCard 
            title="Due Date Calculator"
            subtitle="Get Your Schedule"
            description="Estimate your baby's arrival date and key pregnancy milestones with our easy-to-use calculator."
            icon={Calendar}
          />
        </Link>
        <Link to="/baby-kick-tracker" className="block h-full">
          <ToolCard 
            title="Baby Kick Tracker"
            subtitle="Monitor from Week 28"
            description="Keep track of your baby's movements and ensure their well-being with our simple kick counting tool."
            icon={Activity}
          />
        </Link>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Baby Shower Fun</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Link to="/baby-shower-game-babble-unscramble" className="block h-full">
          <ToolCard 
            title="Baby Babble Unscramble"
            subtitle="Fun Word Puzzle Game"
            description="Unscramble baby-related words in this entertaining game perfect for baby showers or family fun."
            icon={Gift}
          />
        </Link>
        <Link to="/celebrity-baby-name-quiz" className="block h-full">
          <ToolCard 
            title="Celebrity Baby Name Quiz"
            subtitle="Test Your Knowledge"
            description="Guess the names of celebrity babies in this fun multiple-choice quiz!"
            icon={Star}
          />
        </Link>

        <Link to="/baby-shower-guess-the-emoji-game" className="block h-full">
          <ToolCard 
            title="Guess the Emoji Game"
            subtitle="Baby Shower Edition"
            description="Test your baby knowledge by guessing what these cute emoji combinations mean!"
            icon={Smile}
          />
        </Link>
      </div>
    </div>
  );
};

const ToolCard = ({ title, subtitle, description, icon: Icon }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-pink-100 h-full flex flex-col">
    <div className="flex items-center mb-4">
      <div className="bg-pink-100 p-3 rounded-full mr-4 flex-shrink-0">
        <Icon size={24} className="text-pink-600" />
      </div>
      <div>
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        <p className="text-sm text-pink-600">{subtitle}</p>
      </div>
    </div>
    <p className="text-gray-600 flex-grow">{description}</p>
  </div>
  
);

export default HomePage;