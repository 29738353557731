import React, { useState, useEffect, useCallback } from 'react';
import { Eye, CheckCircle, XCircle } from 'lucide-react';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';


const babyWords = [
  { original: "Diaper", jumbled: "PRIDAE" },
  { original: "Pacifier", jumbled: "FICIPEAR" },
  { original: "Bottle", jumbled: "TTELBO" },
  { original: "Crib", jumbled: "BRIC" },
  { original: "Stroller", jumbled: "LLERROST" },
  { original: "Onesie", jumbled: "EISONE" },
  { original: "Bib", jumbled: "IBB" },
  { original: "Rattle", jumbled: "TTLAER" },
  { original: "Blanket", jumbled: "KENBALT" },
  { original: "Teething", jumbled: "HTEETGIN" },
  { original: "Lullaby", jumbled: "LYBULAL" },
  { original: "Cradle", jumbled: "DLERAC" },
  { original: "Nursery", jumbled: "RYSREUN" },
  { original: "Highchair", jumbled: "GHIHCHARI" },
  { original: "Booties", jumbled: "TOOBIES" },
  { original: "Mobile", jumbled: "LEBIMO" },
  { original: "Swaddle", jumbled: "WADDLES" },
  { original: "Bassinet", jumbled: "SEIBTNAS" },
  { original: "Burp cloth", jumbled: "PURB THOCL" },
  { original: "Wipes", jumbled: "PIESW" }
];

const GAME_STATE_COOKIE = 'babyBabbleGameState';
const LAST_ACTIVITY_COOKIE = 'babyBabbleLastActivity';
const EXPIRATION_TIME = 5 * 60 * 1000; // 30 minutes in milliseconds

const BabyBabbleUnscramble = () => {
  const [userGuesses, setUserGuesses] = useState({});
  const [wordStatus, setWordStatus] = useState({});
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(null);

  const loadGameState = () => {
    const lastActivity = Cookies.get(LAST_ACTIVITY_COOKIE);
    const savedGameState = Cookies.get(GAME_STATE_COOKIE);

    const now = Date.now();
    if (lastActivity && now - parseInt(lastActivity) < EXPIRATION_TIME && savedGameState) {
      try {
        const parsedGameState = JSON.parse(savedGameState);
        
        if (parsedGameState.userGuesses && Object.keys(parsedGameState.userGuesses).length > 0) {
          setUserGuesses(parsedGameState.userGuesses);
        }
        
        if (parsedGameState.wordStatus && Object.keys(parsedGameState.wordStatus).length > 0) {
          setWordStatus(parsedGameState.wordStatus);
        } 
      } catch (error) {
        console.error('Error parsing saved game state:', error);
      }
    } else {
      Cookies.remove(GAME_STATE_COOKIE);
      Cookies.remove(LAST_ACTIVITY_COOKIE);
    }
  };

  useEffect(() => {
    loadGameState();
  }, []);

  const updateCookies = useCallback(() => {
    const gameState = {
      userGuesses,
      wordStatus
    };
    Cookies.set(GAME_STATE_COOKIE, JSON.stringify(gameState), { expires: 1 });
    Cookies.set(LAST_ACTIVITY_COOKIE, Date.now().toString(), { expires: 1 });
  }, [userGuesses, wordStatus]);

  useEffect(() => {
    updateCookies();
  }, [updateCookies]);

  const computeScore = () => {
    return Object.values(wordStatus).filter(status => status === true).length;
  };

  const handleGuess = (index, guess) => {
    setUserGuesses(prev => {
      const newGuesses = { ...prev, [index]: guess };
      return newGuesses;
    });

    const isCorrect = guess.toLowerCase() === babyWords[index].original.toLowerCase();
    if (isCorrect && wordStatus[index] !== true) {
      setWordStatus(prev => {
        const newStatus = { ...prev, [index]: true };
        return newStatus;
      });
    }
  };

  const handleRevealRequest = (index) => {
    if (wordStatus[index] !== true) {
      setCurrentWordIndex(index);
      setShowWarningModal(true);
    }
  };

  const handleReveal = () => {
    if (currentWordIndex !== null) {
      setWordStatus(prev => {
        const newStatus = { ...prev, [currentWordIndex]: false };
        return newStatus;
      });
      setShowWarningModal(false);
      setCurrentWordIndex(null);
    }
  };

  return (
    <>
      <Helmet>
        <title>Baby Babble Unscramble Game | Mumma Care</title>
        <meta name="description" content="Play our fun Baby Babble Unscramble game! Unscramble baby-related words in this entertaining puzzle perfect for baby showers or family fun." />
        <meta name="keywords" content="baby shower game, word puzzle, baby words, unscramble game" />
      </Helmet>
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Baby Babble Unscramble</h1>
      <p className="text-xl text-gray-600 mb-2">Unscramble these baby-related words. Type your guess and see if you're correct!</p>
      <p className=" mb-4" style={{ fontSize: 'small' }}>The game can be reset in 5 minutes by refreshing the page.</p>
      <div className="flex justify-between items-center mb-8">
        <p className="text-xl font-bold text-pink-600">Your Score: {computeScore()} / {babyWords.length}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {babyWords.map((word, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <div className="flex justify-between items-center mb-2">
              <span className="font-semibold text-lg">{word.jumbled}</span>
              <button 
                onClick={() => handleRevealRequest(index)}
                className="text-pink-500 hover:text-pink-700"
                disabled={wordStatus[index] === true}
              >
                <Eye />
              </button>
            </div>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Your guess"
                className="flex-grow border rounded-md px-2 py-1 mr-2"
                value={userGuesses[index] || ''}
                onChange={(e) => handleGuess(index, e.target.value)}
                disabled={wordStatus[index] !== undefined}
              />
              {wordStatus[index] !== undefined && (
                wordStatus[index] ? (
                  <CheckCircle className="text-green-500" />
                ) : (
                  <XCircle className="text-red-500" />
                )
              )}
            </div>
            {wordStatus[index] === false && (
              <p className="mt-2 text-gray-600">Answer: {word.original}</p>
            )}
          </div>
        ))}
      </div>

      {showWarningModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-sm">
            <h2 className="text-xl font-bold mb-4">Warning</h2>
            <p className="mb-4">Viewing the answer will mark this word as wrong. Are you sure you want to proceed?</p>
            <div className="flex justify-end space-x-4">
              <button 
                onClick={() => setShowWarningModal(false)}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button 
                onClick={handleReveal}
                className="px-4 py-2 bg-pink-500 text-white rounded-md hover:bg-pink-600"
              >
                Reveal Answer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default BabyBabbleUnscramble;