import React from 'react';
import { Helmet } from 'react-helmet';
import { Mail } from 'lucide-react';

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Mumma Care</title>
        <meta name="description" content="Get in touch with Mumma Care. We're here to support you on your beautiful journey through pregnancy and motherhood." />
      </Helmet>
      <div className="max-w-2xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Contact Us</h1>
        <p className="text-xl text-gray-600 mb-8">
          We're here for you, mama! Whether you have questions, suggestions, or just want to chat about your pregnancy journey, we're all ears. Don't hesitate to reach out – we're in this together!
        </p>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <Mail className="text-pink-600 mr-3" size={24} />
            <h2 className="text-2xl font-semibold text-gray-800">Email Us</h2>
          </div>
          <p className="text-lg text-gray-600">
            Send us an email at: <a href="mailto:hello.kabett@gmail.com" className="text-pink-600 hover:underline">hello.kabett@gmail.com</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactPage;