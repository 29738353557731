import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Mumma Care Privacy Policy</h1>
      <p className="mb-4">Last updated: June 30, 2024</p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-4">Introduction</h2>
      <p className="mb-4">Mumma Care ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Mumma Care mobile application.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Information We Collect</h2>
      <p className="mb-4">We collect two types of information through our application:</p>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">Information you input: All information you input into the app for pregnancy tracking is stored locally on your device and is not transmitted to our servers.</li>
        <li className="mb-2">Analytics data: We use Google Analytics to collect anonymous usage data to help us improve our application.</li>
      </ol>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Google Analytics</h2>
      <p className="mb-4">We use Google Analytics to help us understand how our users use the Mumma Care app. Google Analytics collects information such as:</p>
      <ul className="list-disc list-inside mb-4">
        <li>App usage statistics</li>
        <li>Device information (e.g., device type, operating system)</li>
        <li>Geographic location (country and city level)</li>
        <li>First launches, app updates, and app removes</li>
      </ul>
      <p className="mb-4">This information is collected anonymously and cannot be used to identify individual users. For more information on how Google uses this data, please visit <a href="https://policies.google.com/technologies/partner-sites" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Google's Privacy & Terms site</a>.</p>
      <p className="mb-4">If you don't want Analytics to be used in your browser, you can install the <a href="https://tools.google.com/dlpage/gaoptout" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Google Analytics opt-out browser add-on</a>. Learn more about <a href="https://support.google.com/analytics/answer/6004245" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Google Analytics and privacy</a>.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">How We Use Your Information</h2>
      <p className="mb-4">We use the analytics data collected through Google Analytics to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Analyze app usage patterns</li>
        <li>Improve our application's functionality and user experience</li>
        <li>Diagnose technical issues</li>
        <li>Make informed decisions about future app development</li>
      </ul>
      <p className="mb-4">The information you input for pregnancy tracking is not used by us as it remains on your device.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Data Security</h2>
      <p className="mb-4">We implement appropriate technical and organizational measures to protect the information collected through our app. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Changes to This Privacy Policy</h2>
      <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at:</p>
      <p className="mb-4">Email: hello.kabett@gmail.com</p>
    </div>
  );
};

export default PrivacyPolicy;