import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, Home, Calendar, Activity, Heart, Gift, Star, Lightbulb, Smile } from 'lucide-react';

const Layout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsDrawerOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const menuItems = [
    { name: 'Home', icon: Home, path: '/' },
    { name: 'Due Date Calculator', icon: Calendar, path: '/pregnancy-calculator-due-date-schedule' },
    { name: 'Kick Tracker', icon: Activity, path: '/baby-kick-tracker' },
    { name: 'Babble Scramble', icon: Gift, path: '/baby-shower-game-babble-unscramble' },
    { name: 'Celeb Baby Quiz', icon: Star, path: '/celebrity-baby-name-quiz' },
    { name: 'Guess the Emoji', icon: Smile, path: '/baby-shower-guess-the-emoji-game' }

  ];

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Overlay for mobile */}
      {isDrawerOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden" 
          onClick={toggleDrawer}
        ></div>
      )}

      {/* Left Drawer */}
      <div className={`fixed inset-y-0 left-0 z-30 bg-white shadow-lg transition-all duration-300 ease-in-out ${isDrawerOpen ? 'w-64' : 'w-0 md:w-16'} overflow-hidden`}>
        <div className="flex justify-between items-center p-4">
          {isDrawerOpen && <h2 className="text-xl font-semibold text-gray-800">Menu</h2>}
          <button onClick={toggleDrawer} className="text-gray-600 hover:text-gray-800 focus:outline-none">
            {isDrawerOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        <nav className="mt-8">
          <ul>
            {menuItems.map((item, index) => (
              <li key={index} className="mb-4">
                <Link 
                  to={item.path}
                  className={`flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-pink-500 ${location.pathname === item.path ? 'text-pink-500 font-semibold' : ''}`}
                >
                  <item.icon size={20} className="mr-4" />
                  {isDrawerOpen && <span>{item.name}</span>}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Main Content */}
      <div className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${isDrawerOpen ? 'md:ml-64' : 'md:ml-16'}`}>
        {/* Top Navigation */}
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between p-4">
            <div className="flex items-center">
              <button onClick={toggleDrawer} className="mr-4 text-gray-600 hover:text-gray-800 focus:outline-none md:hidden">
                <Menu size={24} />
              </button>
              <div className="flex items-center cursor-pointer" onClick={navigateHome}>
                <img src="/logo.svg" alt="Mumma Care Logo" className="h-8 w-auto mr-2" />
                <h1 className="text-2xl font-semibold text-gray-800 font-logo">Mumma Care</h1>
              </div>
            </div>
            <a
              href="https://insigh.to/b/mumma-care"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-pink-500 flex items-center"
            >
              <Lightbulb size={16} className="mr-1" />
              <span className="text-sm">Request Features</span>
            </a>
          </div>
        </header>

        {/* Content Area */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto p-8">
          {children}
        </main>

        {/* Footer */}
        <footer className="bg-white border-t border-gray-200">
          <div className="container mx-auto px-4 py-3">
            <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-500">
              <div className="mb-2 md:mb-0">
                <span>© 2024 Mumma Care</span>
                <span className="mx-2">|</span>
                <span className="inline-flex items-center">
                  Built with <Heart size={16} className="text-pink-500 mx-1" /> in SF
                </span>
              </div>
              <div className="flex space-x-4">
                <Link to="/privacy-policy" className="hover:text-pink-500">Privacy Policy</Link>
                <Link to="/terms-of-service" className="hover:text-pink-500">Terms of Service</Link>
                <Link to="/contact" className="hover:text-pink-500">Contact</Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Layout;