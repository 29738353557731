import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Mumma Care Terms and Conditions</h1>
      <p className="mb-4">Last updated: June 30, 2024</p>
      
      <p className="mb-4">Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Mumma Care mobile application (the "Service") operated by Mumma Care ("us", "we", or "our").</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Agreement to Terms</h2>
      <p className="mb-4">By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access or use the Service.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Use of the Service</h2>
      <p className="mb-4">The Mumma Care app is designed to assist in pregnancy tracking. It is not intended to replace professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding your pregnancy.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. User Responsibilities</h2>
      <p className="mb-4">You are responsible for maintaining the confidentiality of any information you input into the app. You agree to use the app responsibly and in compliance with all applicable laws and regulations.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Intellectual Property</h2>
      <p className="mb-4">The Service and its original content, features, and functionality are and will remain the exclusive property of Mumma Care and its licensors.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Disclaimer of Warranties</h2>
      <p className="mb-4">The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Limitation of Liability</h2>
      <p className="mb-4">In no event shall Mumma Care, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Changes to Terms</h2>
      <p className="mb-4">We reserve the right to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">8. Contact Us</h2>
      <p className="mb-4">If you have any questions about these Terms, please contact us at:</p>
      <p className="mb-4">Email: hello.kabett@gmail.com</p>
    </div>
  );
};

export default TermsOfService;