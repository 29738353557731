import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/HomePage';
import DueDateCalculator from './components/DueDateCalculator';
import KickTracker from './components/KickTracker';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import BabyBabbleUnscramble from './components/BabyBabbleUnscramble';
import CelebrityBabyNameQuiz from './components/CelebrityBabyNameQuiz';
import ContactPage from './components/ContactPage';
import BabyShowerGuessTheEmojiGame from './components/BabyShowerGuessTheEmojiGame';


const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pregnancy-calculator-due-date-schedule" element={<DueDateCalculator />} />
          <Route path="/baby-shower-game-babble-unscramble" element={<BabyBabbleUnscramble />} />
          <Route path="/celebrity-baby-name-quiz" element={<CelebrityBabyNameQuiz />} />
          <Route path="/baby-shower-guess-the-emoji-game" element={<BabyShowerGuessTheEmojiGame />} />
          <Route path="/baby-kick-tracker" element={<KickTracker />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* Add other routes here */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;