import React, { useState, useEffect, useCallback } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';



const quizQuestions = [
    {
      celebrity: "Beyoncé and Jay-dueZ",
      correctAnswer: "Blue Ivy",
      options: ["Blue Ivy", "Zuma", "Hazel", "Raddix"]
    },
    {
      celebrity: "Kim Kardashian and Kanye West",
      correctAnswer: "North",
      options: ["North", "Dusty", "Onyx", "Bear"]
    },
    {
      celebrity: "Gwyneth Paltrow and Chris Martin",
      correctAnswer: "Apple",
      options: ["Daisy", "Apple", "Peach", "Violet"]
    },
    {
      celebrity: "Tom Cruise and Katie Holmes",
      correctAnswer: "Suri",
      options: ["Suri", "Sparrow", "Bronx", "Bowie"]
    },
    {
      celebrity: "Will Smith and Jada Pinkett Smith",
      correctAnswer: "Willow",
      options: ["Willow", "Rocket", "Rumer", "Phineas"]
    },
    {
      celebrity: "David and Victoria Beckham",
      correctAnswer: "Harper",
      options: ["Harper", "Aleph", "Lyra", "Exton"]
    },
    {
      celebrity: "Angelina Jolie and Brad Pitt",
      correctAnswer: "Shiloh",
      options: ["Shiloh", "Cy", "Birdie", "Poppy"]
    },
    {
      celebrity: "Gwen Stefani and Gavin Rossdale",
      correctAnswer: "Apollo",
      options: ["Apollo", "Romy", "Sylvester", "Cosmo"]
    },
    {
      celebrity: "Mariah Carey and Nick Cannon",
      correctAnswer: "Moroccan",
      options: ["Moroccan", "Gravity", "Pilot", "Sage"]
    },
    {
      celebrity: "Kourtney Kardashian and Scott Disick",
      correctAnswer: "Reign",
      options: ["Reign", "Bodhi", "Luca", "Axel"]
    },
    {
      celebrity: "Cardi B and Offset",
      correctAnswer: "Kulture",
      options: ["Kulture", "Psalm", "Raddix", "Rumi"]
    },
    {
      celebrity: "Ryan Reynolds and Blake Lively",
      correctAnswer: "James",
      options: ["James", "Everly", "Silas", "Milo"]
    },
    {
      celebrity: "Alicia Keys and Swizz Beatz",
      correctAnswer: "Egypt",
      options: ["Egypt", "Titan", "Kal-El", "Zion"]
    },
    {
      celebrity: "Pink and Carey Hart",
      correctAnswer: "Willow",
      options: ["Willow", "Cypress", "Odette", "Finn"]
    },
    {
      celebrity: "Chrissy Teigen and John Legend",
      correctAnswer: "Luna",
      options: ["Luna", "Ziggy", "Vida", "Gideon"]
    },
    {
      celebrity: "Kylie Jenner and Travis Scott",
      correctAnswer: "Stormi",
      options: ["Stormi", "Sonnet", "Revel", "Phoenix"]
    },
    {
      celebrity: "Jennifer Lopez and Marc Anthony",
      correctAnswer: "Emme",
      options: ["Emme", "Dusty", "Geo", "Boomer"]
    },
    {
      celebrity: "Nicole Kidman and Keith Urban",
      correctAnswer: "Sunday",
      options: ["Sunday", "Rocket", "Harlow", "Jasper"]
    },
    {
      celebrity: "Hilary Duff and Mike Comrie",
      correctAnswer: "Luca",
      options: ["Luca", "Bingham", "Olive", "Otis"]
    },
    {
      celebrity: "Neil Patrick Harris and David Burtka",
      correctAnswer: "Harper",
      options: ["Harper", "Esmeralda", "Dashiel", "Wyatt"]
    }
  ];

const GAME_STATE_COOKIE = 'celebrityBabyQuizState';
const LAST_ACTIVITY_COOKIE = 'celebrityBabyQuizLastActivity';
const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

const CelebrityBabyNameQuiz = () => {
  const [userAnswers, setUserAnswers] = useState({});

  const loadGameState = () => {
    const lastActivity = Cookies.get(LAST_ACTIVITY_COOKIE);
    const savedGameState = Cookies.get(GAME_STATE_COOKIE);

    const now = Date.now();
    if (lastActivity && now - parseInt(lastActivity) < EXPIRATION_TIME && savedGameState) {
      try {
        const parsedGameState = JSON.parse(savedGameState);
        
        if (parsedGameState.userAnswers && Object.keys(parsedGameState.userAnswers).length > 0) {
          setUserAnswers(parsedGameState.userAnswers);
        }
      } catch (error) {
        console.error('Error parsing saved game state:', error);
      }
    } else {
      Cookies.remove(GAME_STATE_COOKIE);
      Cookies.remove(LAST_ACTIVITY_COOKIE);
    }
  };

  useEffect(() => {
    loadGameState();
  }, []);

  const updateCookies = useCallback(() => {
    const gameState = {
      userAnswers
    };
    Cookies.set(GAME_STATE_COOKIE, JSON.stringify(gameState), { expires: 1 });
    Cookies.set(LAST_ACTIVITY_COOKIE, Date.now().toString(), { expires: 1 });
  }, [userAnswers]);

  useEffect(() => {
    updateCookies();
  }, [updateCookies]);

  const handleAnswer = (index, answer) => {
    setUserAnswers(prev => ({ ...prev, [index]: answer }));
  };

  const computeScore = () => {
    return Object.entries(userAnswers).filter(([index, answer]) => 
      answer === quizQuestions[parseInt(index)].correctAnswer
    ).length;
  };

  return (
    <>
      <Helmet>
        <title>Celebrity Baby Name Quiz | Mumma Care</title>
        <meta name="description" content="Test your knowledge of celebrity baby names with our fun multiple-choice quiz. Perfect for baby showers or entertainment during your pregnancy journey." />
        <meta name="keywords" content="celebrity baby names, baby name quiz, celebrity quiz, baby shower game" />
      </Helmet>
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Celebrity Baby Name Quiz</h1>
      <p className="text-xl text-gray-600 mb-2">Guess the celebrity baby names from the options provided!</p>
      <p className="mb-4" style={{ fontSize: 'small' }}>The game can be reset in 5 minutes by refreshing the page.</p>
      <p className="text-xl font-bold text-pink-600 mb-8">Your Score: {computeScore()} / {quizQuestions.length}</p>

      <div className="space-y-8">
        {quizQuestions.map((question, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4">{question.celebrity}'s baby is named:</h3>
            <div className="grid grid-cols-2 gap-4">
              {question.options.map((option, optionIndex) => (
                <button
                  key={optionIndex}
                  onClick={() => handleAnswer(index, option)}
                  className={`p-2 rounded-md ${
                    userAnswers[index] === option
                      ? option === question.correctAnswer
                        ? 'bg-green-200'
                        : 'bg-red-200'
                      : 'bg-gray-100 hover:bg-gray-200'
                  } transition-colors`}
                  disabled={userAnswers[index] !== undefined}
                >
                  {option}
                  {userAnswers[index] === option && (
                    option === question.correctAnswer ? (
                      <CheckCircle className="inline-block ml-2 text-green-500" size={20} />
                    ) : (
                      <XCircle className="inline-block ml-2 text-red-500" size={20} />
                    )
                  )}
                </button>
              ))}
            </div>
            {userAnswers[index] && userAnswers[index] !== question.correctAnswer && (
              <p className="mt-2 text-sm text-gray-600">Correct answer: {question.correctAnswer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default CelebrityBabyNameQuiz;