import React, { useState, useEffect, useCallback } from 'react';
import { PlusCircle, RotateCcw, StopCircle } from 'lucide-react';
import { Helmet } from 'react-helmet';


const KickTracker = () => {
  const [isTracking, setIsTracking] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [kicks, setKicks] = useState([]);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);

  useEffect(() => {
    let interval;
    if (isTracking) {
      interval = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTracking, startTime]);

  const startTracking = () => {
    setIsTracking(true);
    setStartTime(Date.now());
  };

  const stopTracking = () => {
    setIsTracking(false);
    setShowStopModal(false);
  };

  const resetTracking = useCallback(() => {
    setIsTracking(false);
    setStartTime(null);
    setElapsedTime(0);
    setKicks([]);
    setShowResetModal(false);
  }, []);

  const recordKick = () => {
    if (isTracking) {
      setKicks(prevKicks => [...prevKicks, { number: prevKicks.length + 1, time: elapsedTime }]);
    }
  };

  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
  };

  return (
    <>
    <Helmet>
      <title>Baby Kick Tracker | Mumma Care</title>
      <meta name="description" content="Monitor your baby's movements from week 28 with our simple and effective kick counting tool. Ensure your baby's well-being during pregnancy." />
      <meta name="keywords" content="baby kick tracker, fetal movement, kick counting, pregnancy monitoring" />
    </Helmet>
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Baby Kick Tracker</h1>
      
      <div className="mb-8 text-center">
        <p className="text-6xl font-bold text-gray-800">{formatTime(elapsedTime)}</p>
      </div>

      <div className="mb-8 space-y-4">
        {!isTracking ? (
          <button onClick={startTracking} className="w-full bg-pink-500 text-white px-6 py-3 rounded-lg text-xl font-semibold hover:bg-pink-600 transition duration-300">Start Tracking</button>
        ) : (
          <>
            <button onClick={recordKick} className="w-full bg-pink-500 text-white px-6 py-4 rounded-lg text-2xl font-semibold hover:bg-pink-600 transition duration-300 flex items-center justify-center">
              <PlusCircle className="inline mr-2" size={28} />
              Record Kick
            </button>
            <button onClick={() => setShowStopModal(true)} className="w-full bg-red-500 text-white px-6 py-3 rounded-lg text-xl font-semibold hover:bg-red-600 transition duration-300 flex items-center justify-center">
              <StopCircle className="inline mr-2" size={24} />
              Stop
            </button>
          </>
        )}
        <button onClick={() => setShowResetModal(true)} className="w-full bg-gray-300 text-gray-700 px-6 py-3 rounded-lg text-xl font-semibold hover:bg-gray-400 transition duration-300 flex items-center justify-center">
          <RotateCcw className="inline mr-2" size={24} />
          Reset
        </button>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Recorded Kicks</h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-pink-100">
                <th className="border border-gray-300 px-4 py-2 text-pink-700">Kick Number</th>
                <th className="border border-gray-300 px-4 py-2 text-pink-700">Time</th>
              </tr>
            </thead>
            <tbody>
              {kicks.map((kick) => (
                <tr key={kick.number} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2 text-center">{kick.number}</td>
                  <td className="border border-gray-300 px-4 py-2 text-center">{formatTime(kick.time)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 rounded-r-lg" role="alert">
        <p className="font-bold">Disclaimer:</p>
        <p>We do not store your kick tracking results. Please make sure to record this information elsewhere for your records.</p>
      </div>

      {showResetModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Confirm Reset</h2>
            <p className="mb-6 text-gray-600">Are you sure you want to reset the tracker? This will clear all recorded kicks.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowResetModal(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300">Cancel</button>
              <button onClick={resetTracking} className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300">Reset</button>
            </div>
          </div>
        </div>
      )}

      {showStopModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Confirm Stop</h2>
            <p className="mb-6 text-gray-600">Are you sure you want to stop tracking? You can resume later, but the timer will reset.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowStopModal(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300">Cancel</button>
              <button onClick={stopTracking} className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300">Stop</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default KickTracker;