import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';


const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

export const useGameState = (gameName) => {
  const [gameState, setGameState] = useState({});
  const [resetTime, setResetTime] = useState(null);

  const GAME_STATE_COOKIE = `${gameName}GameState`;
  const LAST_ACTIVITY_COOKIE = `${gameName}LastActivity`;

  const loadGameState = useCallback(() => {
    const lastActivity = Cookies.get(LAST_ACTIVITY_COOKIE);
    const savedGameState = Cookies.get(GAME_STATE_COOKIE);

    const now = Date.now();
    if (lastActivity && now - parseInt(lastActivity) < EXPIRATION_TIME && savedGameState) {
      try {
        const parsedGameState = JSON.parse(savedGameState);
        setGameState(parsedGameState);
        setResetTime(parseInt(lastActivity) + EXPIRATION_TIME);
      } catch (error) {
        console.error('Error parsing saved game state:', error);
      }
    } else {
      Cookies.remove(GAME_STATE_COOKIE);
      Cookies.remove(LAST_ACTIVITY_COOKIE);
      setGameState({});
      setResetTime(now + EXPIRATION_TIME);
    }
  }, [GAME_STATE_COOKIE, LAST_ACTIVITY_COOKIE]);

  const saveGameState = useCallback((newState) => {
    setGameState(newState);
    Cookies.set(GAME_STATE_COOKIE, JSON.stringify(newState), { expires: 1 });
    const now = Date.now();
    Cookies.set(LAST_ACTIVITY_COOKIE, now.toString(), { expires: 1 });
    setResetTime(now + EXPIRATION_TIME);
  }, [GAME_STATE_COOKIE, LAST_ACTIVITY_COOKIE]);

  useEffect(() => {
    loadGameState();
    const interval = setInterval(loadGameState, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [loadGameState]);

  const formatTimeRemaining = () => {
    if (!resetTime) return '';
    const remaining = Math.max(0, resetTime - Date.now());
    const minutes = Math.floor(remaining / 60000);
    const seconds = Math.floor((remaining % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return { gameState, saveGameState, formatTimeRemaining };
};